import Head from 'next/head';
import { useRouter } from 'next/router';

export default function Header(props) {
  const router = useRouter();
  const { locale } = router;

  console.log(locale);

  const CANONICAL_DOMAIN = 'https://whiteclover.io';

  const _pathSliceLength = Math.min.apply(Math, [
    router.asPath.indexOf('?') > 0 ? router.asPath.indexOf('?') : router.asPath.length,
    router.asPath.indexOf('#') > 0 ? router.asPath.indexOf('#') : router.asPath.length,
  ]);
  const canonicalURL = CANONICAL_DOMAIN + router.asPath.substring(0, _pathSliceLength);

  return (
    <div>
      <Head>
        {locale === 'en' ? (
          <>
            <title>WhiteClover - Wedding Planning and Social Experience App</title>
            <meta
              name="keywords"
              content="weddings, rsvp, friends, photos, experience app, invitations, save the day, white clover, whiteclover, custom website"
            />
            <meta
              name="description"
              content="Organise your wedding, build your website, invite guest with RSVP and let your friends to build your special day album from their view."
              key="description"
            />
            <meta httpEquiv="content-language" content="en" key="metaContent" />
            <meta property="og:image" content="https://app.whiteclover.io/share-photo.jpg" key="ogImage" />
          </>
        ) : (
          <>
            <title>WhiteClover - Οργάνωση γάμου και Social Experience App</title>
            <meta
              name="keywords"
              content="weddings, rsvp, friends, photos, experience app, invitations, save the day, white clover, whiteclover, custom website, οργάνωση, γάμος, μοναδικές στιγμές"
            />
            <meta
              name="description"
              content="Δημιούργησε το website σου και οργάνωσε τον γάμο σου ενώ οι φίλου μπορούν να συλλέγουν μοναδικές στιγμές για το album σου."
              key="description"
            />
            <meta httpEquiv="content-language" content="el" key="metaContent" />
            <meta property="og:image" content="https://app.whiteclover.io/share-photo.jpg" key="ogImage" />
          </>
        )}

        {process.env.CDN_PROVIDER_URL && (
          <>
            <link rel="preconnect" href="https://rest-filestore.whiteclover.uk" />
            <link rel="preconnect" href={process.env.CDN_PROVIDER_URL} />
            <link
              rel="preload"
              href={`${process.env.CDN_PROVIDER_URL}/fonts/Friday-Regular.ttf`}
              as="font"
              type="font/ttf"
              crossOrigin="anonymous"
            />
            <link
              rel="preload"
              href={`${process.env.CDN_PROVIDER_URL}/fonts/DidactGothic-Regular.ttf`}
              as="font"
              type="font/ttf"
              crossOrigin="anonymous"
            />
          </>
        )}
        <link rel="preconnect" href="https://www.googletagmanager.com" />
        <link rel="preconnect" href="https://config.confirmic.com" />
        <link rel="preconnect" href="https://consent-manager.confirmic.com" />
        <link rel="preconnect" href="https://embed.tawk.to" />

        <link rel="icon" href="/static/favicon.ico" />

        <meta httpEquiv="content-type" content="text/html;charset=UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=8.0" />
        <meta name="author" content="Nima Project" />
        <meta name="copyright" content="&copy; NIMA PROJECT 2021" />

        <link rel="manifest" href="/manifest.json" />
        <link rel="apple-touch-icon" href="/whiteclover-app-512x512.png" />
        <meta name="theme-color" content="#3f3d56" />

        <link rel="canonical" href={canonicalURL} />
        <meta name="og:url" content={canonicalURL} key="ogURL" />

        <meta name="og:site_name" content="Whiteclover" />

        <meta property="og:title" content="Whiteclover • Your big day planner" key="ogTitle" />
        <meta
          property="og:description"
          content="Plan your wedding and let your friends help you with the photos"
          key="ogDesc"
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content="Whiteclover" />
        <meta name="twitter:title" content="Whiteclover • Your big day planner" key="twitterTitle" />
        <meta
          name="twitter:description"
          content="Plan your wedding and let your friends help you with the photos"
          key="twitterDesc"
        />
      </Head>
    </div>
  );
}
